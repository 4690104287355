import {React, useEffect} from "react";
import Sources from "./components/Sources";
import { getToken } from "../cookie.ts";
import { useNavigate } from "react-router-dom";

export default function CreateChatbot() {
  const navigate = useNavigate();
  var access_token;
  useEffect(() => {
    async function fetchData() {
      // You can await here
      access_token = await getToken();
      if (!access_token) {
        navigate("/login");
      }
      // ...
    }
    fetchData();
  }, []);
  return (
    <div>
      <main id="skip">
        <section className="bg-white">
          <div className=" max-w-7xl mx-auto py-10 sm:py-24 px-4 sm:px-6 lg:px-8">
            <Sources />
          </div>
        </section>
      </main>
    </div>
  );
}
