import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import api from "../../api";
export default function ExistingSources() {
  const { t, i18n } = useTranslation();
  // const isRTL = i18n.dir() === "rtl";
  const params = useParams();
  const navigate = useNavigate();
  const [isdelLoading, setdelLoading] = useState(false);
  const [isfetchLoading, setfetchLoading] = useState(false);

  const handledelloading = () => {
    setdelLoading(true);
  };
  const handlefetchloading = () => {
    setfetchLoading(true);
  };
  const { data: botData, status } = useQuery(
    ["getBotDS", params.id],
    async () => {
      const response = await api.get(`/bot/${params.id}/source`);
      //console.log(response.data);
      return response.data;
    },
    {
      refetchInterval: 1000,
    }
  );
  const { mutate: deleteDs } = useMutation(
    (id) => api.delete(`/bot/${params.id}/source/${id}`),
    {
      onSuccess: () => {
        setdelLoading(false);
        alert(t("Data source deleted successfully"));
      },
      onError: () => {
        setdelLoading(false);
        alert(t("Error while deleting data source"));
      },
    }
  );
  const { mutate: refetchDS } = useMutation(
    (id) => api.post(`/bot/${params.id}/source/${id}/refresh`),
    {
      onSuccess: () => {
        setfetchLoading(false);
        alert(t("Data source updated successfully"));
      },
      onError: () => {
        setfetchLoading(false);
        alert(t("Error while updating data source"));
      },
    }
  );
  useEffect(() => {
    if (status === "error") {
      navigate("/");
    }
  }, [status]);

  return (
    <div>
      <main className="isolate">
        <div className="relative px-6 lg:px-8 py-16 sm:py-8">
          <h1 className=" text-3xl  text-center font-bold">
            {t("Data Sources")}
          </h1>
        </div>
        <div className="container mx-auto px-4 sm:px-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal ">
                <thead className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100">
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-bold uppercase tracking-wider text-black-900">
                      {t("Type")}
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-bold uppercase tracking-wider text-black-900">
                      {t("Content")}
                    </th>

                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-bold uppercase tracking-wider text-black-900">
                      {t("Status")}
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
                  </tr>
                </thead>
                <tbody>
                  {botData?.data?.map((source) => (
                    <>
                      <tr key={source.id}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {t(source.type)}
                            {/* "Files" ,"Text", "Website",
                        "Q&A" */}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap overflow-x-hidden">
                            <span className="inline-block max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                              {source.content.length > 50
                                ? source.content.substring(0, 50) + "..."
                                : source.content}
                            </span>
                          </p>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <span className="relative ">
                              {t(source.status.toUpperCase())}
                            </span>
                          </span>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right">
                          <div className="flex justify-center items-center">
                            {isdelLoading ? (
                              <span className="flex space-x-1">
                                <span className="h-2 w-2 rounded-full animate-color"></span>
                                <span className="h-2 w-2 rounded-full animate-color200"></span>
                                <span className="h-2 w-2 rounded-full animate-color400"></span>
                              </span>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    const confirm = window.confirm(
                                      t(
                                        "Are you sure you want to re-fetch this data source?"
                                      )
                                    );
                                    if (confirm) {
                                      handledelloading();
                                      refetchDS(source.id);
                                    }
                                  }}
                                  className="text-sm py-3 hover:text-zinc-600 text-zinc-700"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    className="h-4 w-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                    ></path>
                                  </svg>
                                </button>
                              </>
                            )}
                            ;
                            {isfetchLoading ? (
                              <span className="flex space-x-1">
                                <span className="h-2 w-2 rounded-full animate-color"></span>
                                <span className="h-2 w-2 rounded-full animate-color200"></span>
                                <span className="h-2 w-2 rounded-full animate-color400"></span>
                              </span>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    const confirm = window.confirm(
                                      t(
                                        "Are you sure you want to delete this data source?"
                                      )
                                    );
                                    if (confirm) {
                                      handlefetchloading();
                                      deleteDs(source.id);
                                    }
                                  }}
                                  type="button"
                                  className="text-zinc-600 hover:text-zinc-900"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 text-red-600 ml-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    ></path>
                                  </svg>
                                </button>
                              </>
                            )}
                            ;
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
