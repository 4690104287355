import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./routes/components/Navbar";
import Footer from "./routes/components/Footer";
import Home from "./routes/components/Home";
import Chatbot from "./routes/components/Chatbot";
import CreateChatbot from "./routes/CreateChatbot";
import OpenChatbot from "./routes/OpenChatbot";
import { I18nextProvider } from "react-i18next";
import Cookies from "js-cookie"; // Import the js-cookie library
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "./i18n";
import Pricing from "./routes/components/Pricing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import Login from "./routes/Login";
import { supabase } from "./supabase";
import ChatbotFrame from "./routes/components/ChatbotFrame";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ar", // Default language if translation is missing
    supportedLngs: ["en", "ar"], // Supported languages
    backend: {
      loadPath: "../public/locales/{{lng}}/{{ns}}.json", // Path to translation files
    },
    detection: {
      order: ["localStorage", "navigator"], // Check saved language preference first, then check browser language
    },
  });
function App() {
  const storedLanguage = Cookies.get("selectedLanguage");
  let [activeLanguage, setActiveLanguage] = useState(storedLanguage);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const shouldShowNavbar = !window.location.pathname.includes("chatbot-iframe");

  // console.log("language:",navigator.language);
  useEffect(() => {
    // This function will check the current session and set the state accordingly
    async function checkSession() {
      const session = await supabase.auth.getSession();
      console.log(JSON.stringify(session?.data?.session?.user?.role));
      if (session?.data?.session?.user?.role === "authenticated") {
        setIsLoggedIn(true);
        console.log("logged in");
      }
      supabase.auth.onAuthStateChange((event) => {
        if (event === "SIGNED_IN") {
          setIsLoggedIn(true);
          // console.log("logged in");
        } else if (event === "SIGNED_OUT") {
          console.log("no login");
          setIsLoggedIn(false);
        }
      });
    }

    // Check the session when the component mounts
    checkSession();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error("Error logging out:", error);
    } else {
      setIsLoggedIn(false);
      window.location.href = "/";
    }
  };
  const queryClient = new QueryClient();

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <QueryClientProvider client={queryClient}>
          {shouldShowNavbar && (
            <Navbar
              isLoggedIn={isLoggedIn}
              handleLogout={handleLogout}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />
          )}

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chatbot" element={<Chatbot />} />
            <Route path="/create-new-chatbot" element={<CreateChatbot />} />
            <Route path="/open-chatbot/:id" element={<OpenChatbot />} />
            <Route path="/chatbot-iframe/:id" element={<ChatbotFrame />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </QueryClientProvider>
      </Router>
      {shouldShowNavbar && <Footer />}
    </I18nextProvider>
  );
}

export default App;
