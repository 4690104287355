export const REACT_APP_VITE_API_URL = "https://alameenbackend-production-e289.up.railway.app/api/v1";
export const REACT_APP_VITE_HOST_URL = "https://alameenbackend-production-e289.up.railway.app";
export const FRONT_END_URL = "https://alameen.itechnologyeg.com";
//export const REACT_APP_VITE_API_URL = "http://localhost:3000/api/v1";
//export const REACT_APP_VITE_HOST_URL = "http://localhost:3000";
//export const FRONT_END_URL = "http://localhost:3001";

export const REACT_APP_VITE_SUPABASE_URL =
  "https://utlirfsauhrskveirtqv.supabase.co";
export const REACT_APP_VITE_SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV0bGlyZnNhdWhyc2t2ZWlydHF2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3ODY1MTgsImV4cCI6MjAxMzM2MjUxOH0.QgvfWuOopjGx69jJZ9JBIqRQMrtfKPDB7DrBbAW9hYA";
export const packages = [
  {
    name: "free",
    total_messages: 30,
    character_limit: 400000,
    total_bot_limit: 1,
  },
  {
    name: "hobby",
    total_messages: 2000,
    character_limit: 11000000,
    total_bot_limit: 2,
  },
  {
    name: "standard",
    total_messages: 5000,
    character_limit: 11000000,
    total_bot_limit: 5,
  },
  {
    name: "unlimited",
    total_messages: 40000,
    character_limit: 11000000,
    total_bot_limit: 10,
  },
];

export function findPackage(name) {
  for (let index = 0; index < packages.length; index++) {
    const element = packages[index];
    if (element.name == name) {
      return element;
    }
  }
  return null;
}
