import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../hooks/useMessage.ts";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router";
import api from "../../api.js";
import { findPackage } from "../../config.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { fireEvent } from "@testing-library/react";
import { REACT_APP_VITE_API_URL } from "../../config.js";
export default function ChatbotFrame({ chatboxID, showCredits }) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const navigate = useNavigate();

  // const { messages, clearChat } = useMessage();

  const [writtenMsg, setWrittenMsg] = useState("");

  const [botname, setbotname] = useState("");
  const [sentMsgColor, setsentMsgColor] = useState("#3B81F6");
  const [first_message, setfirst_message] = useState(
    "Hi, I'm here to help. What can I do for you today?"
  );

  const [messagesRemaining, setmessagesRemaining] = useState(0);

  const [showCreditsLeft, setShowCreditsLeft] = useState(false);

  const handleclearChat = () => {
    clearChat();
  };
  let { id } = useParams();
  if (!id) {
    id = chatboxID;
  }
  useEffect(() => {
    if (showCredits) {
      setShowCreditsLeft(showCredits);
    }
  }, [showCredits]);

  const { onSubmit, messages, clearChat } = useMessage(id);
  // console.log(writtenMsg);
  // console.log(setWrittenMsg);
  const { mutateAsync: sendMessage, isLoading: isSending } = useMutation(
    onSubmit,
    {
      onSuccess: async () => {
        // form.setFieldValue("message", "");
        const packageResponse = await api.get(`/user/packageDetails`);
        // console.log("PackageResponse: " + JSON.stringify(packageResponse));
        let packagedetails = findPackage(packageResponse.data.data.packageName);
        let remainingCredit =
          packagedetails.total_messages - packageResponse.data.data.messageSent;
          console.log(packageResponse.status);

        setmessagesRemaining(remainingCredit);
      },
      onError: (error) => {
        console.error(error);
        if(error.response.status && error.response.status == 401) {
          navigate("/login");
        }
      },
    }
  );
  useEffect(() => {
    async function getMsgColor() {
      try {
        // console.log("Bot Id: " + id);
        const response = await axios.get(
          `${REACT_APP_VITE_API_URL}/bot/appearance/${id}`
        );
        //console.log(JSON.stringify(response));
        //console.log(response.data.data.bot_name);

        setsentMsgColor(
          response.data.data.chat_human_bubble_style.background_color
        );
        setbotname(response.data.data.bot_name);
        setfirst_message(response.data.data.first_message);

        const packageResponse = await api.get(`/user/packageDetails`);
        //console.log("PackageResponse: " + JSON.stringify(packageResponse));
        let packagedetails = findPackage(packageResponse.data.data.packageName);
        let remainingCredit =
          packagedetails.total_messages - packageResponse.data.data.messageSent;
        setmessagesRemaining(remainingCredit);
      } catch (error) {
        console.log(error);
      }
    }
    getMsgColor();
  }, []);

  function getMsg(event) {
    //console.log(event.target.value);
    setWrittenMsg(event.target.value);
  }

  async function clickedMsg() {
    try {
      setWrittenMsg("");
      await sendMessage(writtenMsg);

      //  console.log(writtenMsg);
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  }
  function handleKeyUp(event) {
    if (event.key === "Enter") {
      clickedMsg();
    }
  }

  return (
    <div>
      <div id="next">
        <div
          className="px-4 flex flex-col "
          style={{ background: "white" }}
        >
          <div className=" sticky top-0 w-full">
            <div
              className="flex justify-between py-1 mb-4 z-10"
              style={{ borderBottom: "1px solid #f1f1f0", background: "white" }}
            >
              {/* <div className="flex items-center">
                <img
                  src={logoen}
                  width="115"
                  height="115"
                  alt="profile"
                  className="rounded-full m-1 mr-2"
                  style={{ color: "transparent" }}
                />
              </div> */}

              <div className="flex items-center">
                <h1 className="text-med font-bold text-zinc-700">{botname}</h1>
              </div>
              <div className="flex justify-center items-center">
                <button
                  onClick={handleclearChat}
                  className="text-sm py-3   hover:text-zinc-600 text-zinc-700"
                >
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-grow ">
            {first_message.split("\n").map((line, index) => (
              <div
                key={index}
                className={`flex justify-start mr-8`}
                id="First Msg"
              >
                <div
                  className={`overflow-auto max-w-prose mb-3 rounded-lg py-3 px-4 bg-gray-200`}
                >
                  <div className="flex flex-col items-start gap-4 break-words">
                    <div className="prose text-inherit text-left w-full break-words dark:prose-invert">
                      <p
                        // You may want to use a more stable key if you have one
                        style={{ 
                          direction: isRTL ? "rtl" : "ltr",
                          textAlign: isRTL ? "right" : "left",
                          lineHeight: '2rem'
                        }}
                      >
                        {t(line)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {messages.map((message, index) => (
              <div
                className={`flex ${
                  message.type === "user" ? "justify-end" : "justify-start"
                } mr-8`}
                key={index}
              >
                <div
                  className={`overflow-auto max-w-prose mb-3 rounded-lg py-3 px-4 ${
                    message.type === "user"
                      ? `bg-[${sentMsgColor}] text-right text-white`
                      : "bg-gray-200"
                  }`}
                >
                  <div className="flex flex-col items-start gap-4 break-words">
                    <div className="prose text-inherit text-left w-full break-words dark:prose-invert">
                      <p style={{ direction: isRTL ? "rtl" : "ltr" }}>
                        {t(message.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* {chatHistory.map((single_msg) => (
              <div className="flex justify-start mr-8">
                <div
                  className=" overflow-auto max-w-prose mb-3 rounded-lg py-3 px-4"
                  style={{ backgroundColor: "#f1f1f0", color: "black" }}
                >
                  <div className="flex flex-col items-start gap-4 break-words">
                    <div className="prose text-inherit text-left w-full break-words dark:prose-invert ">
                      <p style={{ direction: isRTL ? "rtl" : "ltr" }}>
                        {t(`${single_msg.msg}`)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
          <div
            className="bottom-0 pb-2 bg-inherit "
            style={{ direction: isRTL ? "rtl" : "ltr" }}
          >
            {showCreditsLeft && (
              <div className="py-3 flex overflow-x-auto">
                <a>
                  {messagesRemaining} {t("message credits left")}
                </a>
                {/* <button className="rounded-xl whitespace-nowrap  mr-1 mt-1 py-2 px-3 text-sm   bg-zinc-100 hover:bg-zinc-200">
              {t("What is Chatbase?")}
            </button> */}
                {/* <button className="rounded-xl whitespace-nowrap  mr-1 mt-1 py-2 px-3 text-sm   bg-zinc-100 hover:bg-zinc-200">
              {t("How do I add data to my chatbot?")}
            </button> */}
              </div>
            )}

            <div
              className="flex pl-3 p-1 rounded "
              style={{ background: "white", border: "1px solid #e4e4e7" }}
            >
              <div className="flex items-center w-full">
                <input
                  style={{ height: "24px" }}
                  onChange={getMsg}
                  value={writtenMsg}
                  type="text"
                  onKeyUp={handleKeyUp}
                  className=" m-0 w-full min-h-[1.5rem] max-h-36 pr-7 resize-none border-0 bg-inherit flex-1 appearance-none rounded-md focus:ring-0 focus-visible:ring-0 focus:outline-none "
                />
              </div>
              <div className="flex items-end">
                <button onClick={clickedMsg} className=" flex-none p-2">
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
