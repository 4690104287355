import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api";
import "../../App.css";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { findPackage } from "../../config";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import mammoth from "mammoth";
// import axios from "axios";
export default function Sources() {
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // handle link input add ,delete and delete all
  const [links, setLinks] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [urltofetch, seturltofetch] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [selectedType, setSelectedType] = useState("files"); // State to keep track of the selected type
  const [text, setText] = useState("");
  // QnA section add, delete,delete all
  const [qna, setqna] = useState([{ question: "", answer: "" }]);
  const [combinedQna, setCombinedQna] = useState(""); //All QnA  are stored in combinedQna
  const [showqna, setShowqna] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    fetchLinks: false,
    createChatbot: false,
  });
  const [isLoading, setisLoading] = useState(false);
  const [totalCharactersLimit, settotalCharactersLimit] = useState(400000);

  const [selectedfilecharCounts, setSelectedfilecharCounts] = useState([]);
  const [characterCount, setcharacterCount] = useState(0);
  useEffect(() => {
    async function getdata() {
      try {
        setisLoading(true);
        const response = await api.get(`/user/packageDetails`);
        //console.log("Package Details: " + JSON.stringify(response));
        let temppackagename = response.data.data.packageName.toLowerCase();
        // setcurrentPackageName(temppackagename);
        settotalCharactersLimit(findPackage(temppackagename).character_limit);

        setisLoading(false);
      } catch (error) {
        console.log(error.message);
        // alert("Package Details Error: " + JSON.stringify(error));
      }
    }
    getdata();
  }, []);

  // Function to toggle loading state for a specific button
  const toggleLoadingState = (buttonName, isLoading) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [buttonName]: isLoading,
    }));
  };
  const getNumCharsFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let totalChars = 0;
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      totalChars += content.items.reduce(
        (acc, item) => acc + item.str.length,
        0
      );
    }
    return totalChars;
  };
  const getNumCharsFromDOCX = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async function (event) {
        const arrayBuffer = event.target.result;
        const { value: text } = await mammoth.extractRawText({
          arrayBuffer: arrayBuffer,
        });
        resolve(text.length);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const getNumCharsFromTXT = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const text = event.target.result;
        resolve(text.length);
      };
      reader.onerror = reject;
      reader.readAsText(file, "UTF-8");
    });
  };
  const handleFileChange = async (event) => {
    const newFiles = event.target.files;
    console.log(event.target.files[0]);
    // Append the new files to the existing selectedFiles state
    let counts = [];
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      let numChars;
      if (file.type === "application/pdf") {
        numChars = await getNumCharsFromPDF(file);
        console.log(`PDF: ${numChars} characters`);
      } else if (file.name.endsWith(".docx")) {
        numChars = await getNumCharsFromDOCX(file);
        console.log(`DOCX: ${numChars} characters`);
      } else if (file.name.endsWith(".txt")) {
        numChars = await getNumCharsFromTXT(file);
        console.log(`TXT: ${numChars} characters`);
      }
      counts.push(numChars);
    }

    setSelectedfilecharCounts((prevData) => [...prevData, ...counts]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
  };

  const handleFileDelete = (fileIndex) => {
    // Remove the file at the specified index from the selectedFiles state
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex)
    );
  };

  async function createNewChatbot(buttonName) {
    try {
      if (characterCount > totalCharactersLimit) {
        alert("Character Limit Reached.");
        return;
      }
      console.log("Creating ChatBot");

      let values = {
        model: "gpt-3.5-turbo",
        embedding: "openai",
      };
      toggleLoadingState(buttonName, true);
      if (selectedType === "files") {
        values.file = [];

        selectedFiles.forEach((file, index) => {
          let uid = uuidv4().toString();
          // console.log(file);
          file.uid = uid;
          file.originFileObj = { uid };
          values.file.push(file);
        });
        const response = await api.post(
          `/bot/upload?embedding=${values.embedding}&model=${values.model}`,
          values,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(JSON.stringify(response.data));
        alert(t("Bot Created Successfully."));
        toggleLoadingState(buttonName, false);
        navigate("/open-chatbot/" + response.data.id);
      } else {
        let type = "text";
        values.content = text;
        if (selectedType === "website") {
          values.links = links;
          type = "website";
          values.content = urltofetch;
        }
        if (selectedType === "qna") {
          values.content = combinedQna;
        }
        console.log(values);
        const response = await api.post("/bot", {
          type,
          ...values,
        });
        console.log(JSON.stringify(response.data));
        alert(t("Bot Created Successfully."));
        toggleLoadingState(buttonName, false);
        navigate("/open-chatbot/" + response.data.id);
        // return response.data;
      }
    } catch (error) {
      console.log(error);
      alert(t("Failed."));
    }
  }
  const handleFileClick = () => {
    fileInputRef.current.click(); // Trigger click on the hidden input element
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handlecharCount = (event) => {
    setText(event.target.value);
  };

  async function fetchAllLinks(buttonName) {
    console.log("Fetching Links");

    toggleLoadingState(buttonName, true);
    const response = await api.post(`/bot/links`, {
      websiteurl: urltofetch,
      maxDepth: 20,
      maxLinks: 20,
    });
    console.log(JSON.stringify(response));

    setLinks(response.data.links);

    toggleLoadingState(buttonName, false);
    return response.data;
  }
  useEffect(() => {
    let tempTotalLength = 0;
    for (let i = 0; i < links.length; i++) {
      tempTotalLength += links[i].length;
    }
    setTotalLength(tempTotalLength);
  }, [links]);

  const handleUrltoFetchChange = (event) => {
    seturltofetch(event.target.value);
  };
  const handleAddLink = () => {
    setLinks([...links, { link: "", length: 0 }]);
    setShowInputs(true);
  };

  const handleDeleteLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const handleDeleteAllLinks = () => {
    setLinks([]);
    setShowInputs(false);
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = { link: value, length: 0 };
    setLinks(newLinks);
  };
  const showDeleteAllButton = links.length > 0;

  const handleAddqna = () => {
    setqna([...qna, { question: "", answer: "" }]);
    setShowqna(true);
  };

  const handleDeleteqna = (index) => {
    const newqna = [...qna];
    newqna.splice(index, 1);
    setqna(newqna);
    updateCombinedQna(newqna);
  };

  const handleDeleteAllqna = () => {
    setqna([]);
    setShowqna(false);
    setCombinedQna("");
  };

  const handleQnaChange = (index, field, value) => {
    const updatedQnaList = [...qna];
    updatedQnaList[index][field] = value;
    setqna(updatedQnaList);
    updateCombinedQna(updatedQnaList);
  };
  const updateCombinedQna = (qnaArray) => {
    const combined = qnaArray
      .map((item) => `${item.question}${item.answer}`)
      .join("\n");
    setCombinedQna(combined);
  };
  useEffect(() => {
    if (selectedType === "website") {
      //characterCount = totalLength;
      setcharacterCount(totalLength);
    } else if (selectedType === "qna") {
      //console.log(combinedQna);
      //characterCount = combinedQna.length;
      setcharacterCount(combinedQna.length);
    } else {
      //characterCount = text.length;
      setcharacterCount(text.length);
    }
    const total = selectedfilecharCounts.reduce(
      (accum, currentCount) => accum + currentCount,
      0
    );
    setcharacterCount(total);
  }, [totalLength, combinedQna, text, selectedfilecharCounts]);

  const showDeleteAllqna = qna.length > 0;

  return (
    <div>
      <main className="isolate">
        <div className="relative px-6 lg:px-8 py-16 sm:py-8">
          <h1 className=" text-3xl mb-8 text-center font-bold">
            {t("Data Sources")}
          </h1>
          <div className="pb-8">
            <div className=" text-center space-x-8">
              <button
                onClick={() => handleTypeChange("files")}
                className={`${
                  selectedType === "files"
                    ? "border-violet-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                } inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium`}
              >
                {t("Files")}
              </button>
              <button
                onClick={() => handleTypeChange("text")}
                className={`${
                  selectedType === "text"
                    ? "border-violet-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                } inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium`}
              >
                {t("Text")}
              </button>
              <button
                onClick={() => handleTypeChange("website")}
                className={`${
                  selectedType === "website"
                    ? "border-violet-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                } inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium`}
              >
                {t("Website")}
              </button>
              <button
                onClick={() => handleTypeChange("qna")}
                className={`${
                  selectedType === "qna"
                    ? "border-violet-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                } inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium`}
              >
                {t("Q&A")}
              </button>
            </div>
          </div>
          {selectedType === "files" && (
            <div className="max-w-md m-auto">
              <div>
                <label
                  htmlFor=""
                  className=" text-center block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {t("Upload Files")}
                </label>
                <div
                  role="presentation"
                  tabIndex="0"
                  className="border border-neutral-200 p-16 cursor-pointer"
                  onClick={handleFileClick}
                  // Call the function to trigger file selection when clicking anywhere in the flex container
                >
                  <input
                    ref={fileInputRef}
                    // Set a ref on the input element
                    accept="text/html,.pdf,.doc,.docx,.txt"
                    tabIndex="-1"
                    name="file"
                    multiple
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  <div className="flex flex-col items-center justify-center gap-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      className="h-5 w-5 fill-current"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                      ></path>
                    </svg>
                    <div className="text-center justify-center items-center">
                      <p className="text-sm text-gray-600 ">
                        {t("Drag & drop files here, or click to select files")}
                      </p>
                      <span
                        style={{ direction: isRTL ? "rtl" : "ltr" }}
                        id="file_type_help"
                        className="text-xs text-gray-500 dark:text-gray-300"
                      >
                        {t("Supported File Types: .pdf, .doc, .docx, .txt")}
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  style={{ direction: isRTL ? "rtl" : "ltr" }}
                  className="mt-2 text-sm text-center text-gray-500 dark:text-gray-300"
                >
                  {t(
                    "NOTE: Uploading a PDF using safari doesn't work, we're looking into the issue. Make sure the text is OCR'd, i.e. you can copy it."
                  )}
                </p>
                <div className="pt-8">
                  {selectedFiles.length > 0 && (
                    <div>
                      <p className="font-semibold pb-2">
                        {t("Attached Files")}
                        {/* <span className="text-zinc-500 text-sm ml-1">
                          (236 chars)
                        </span> */}
                      </p>
                      {selectedFiles.map((file, index) => (
                        <>
                          <div className="grid grid-cols-10 pb-4">
                            <div key={index} className="col-span-9">
                              <span className="break-words">{file.name} </span>
                              {selectedfilecharCounts.length > 0 && (
                                <span className="text-zinc-500 text-sm">
                                  {" "}
                                  ({selectedfilecharCounts[index]} chars)
                                </span>
                              )}
                            </div>
                            <div className="flex items-center justify-end">
                              <button onClick={() => handleFileDelete(index)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4  text-red-600 ml-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </>
                      ))}{" "}
                    </div>
                  )}{" "}
                </div>
              </div>
            </div>
          )}
          {selectedType === "text" && (
            <div className="max-w-3xl m-auto">
              <div>
                <textarea
                  name="data"
                  value={text}
                  onChange={handlecharCount}
                  placeholder={t("data")}
                  rows="20"
                  className="my-2 min-w-0 p-1 flex-auto w-full appearance-none rounded-md border border-zinc-900/10 bg-white px-3 shadow-md shadow-zinc-800/5 placeholder:text-zinc-400 focus:border-violet-500 focus:outline-none focus:ring-4 focus:ring-violet-500/10 sm:text-sm text-gray-900"
                ></textarea>
                <p className="text-sm text-gray-600 text-center h-8">
                  {" "}
                  {text.length}
                  {t("characters")}
                </p>
              </div>
            </div>
          )}
          {selectedType === "website" && (
            <div className="max-w-3xl m-auto">
              {/* Website content */}
              <label
                htmlFor=""
                className="block text-sm font-medium leading-6 text-gray-900 my-2"
              >
                {t("Crawl")}
              </label>
              <div className="relative mt-2 rounded-md">
                <div className="flex space-x-2">
                  <input
                    type="text"
                    name="website"
                    className=" flex-auto rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-sm sm:leading-6"
                    placeholder="https://www.example.com"
                    onChange={handleUrltoFetchChange}
                  />
                  <div className="flex justify-center">
                    <a
                      style={{
                        backgroundColor: loadingStates["fetchLinks"]
                          ? "#888888"
                          : "black",
                      }}
                      className="rounded-md px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                      onClick={() => fetchAllLinks("fetchLinks")}
                      disabled={loadingStates["fetchLinks"]}
                    >
                      <div className="flex items-center">
                        <span style={{ marginRight: "0.5rem" }}>
                          {t("Fetch links")}
                        </span>
                        {loadingStates["fetchLinks"] && (
                          <span className="flex space-x-1">
                            <span className="h-2 w-2 rounded-full animate-color"></span>
                            <span className="h-2 w-2 rounded-full animate-color200"></span>
                            <span className="h-2 w-2 rounded-full animate-color400"></span>
                          </span>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  style={{ direction: isRTL ? "rtl" : "ltr" }}
                  className="py-4 text-sm text-zinc-900"
                >
                  {t(
                    "This will crawl all the links starting with the URL (not including files on the website)."
                  )}
                </div>
              </div>
              {/* <div className="flex items-center my-4">
                <hr className="w-full border-gray-300 border-t" />
                <span className="px-2 text-gray-600 whitespace-nowrap">
                  {t("OR")}
                </span>
                <hr className="w-full border-gray-300 border-t" />
              </div>
              <div>
                <label
                  htmlFor="sitemap"
                  className="block text-sm font-medium leading-6 text-gray-900 my-2"
                >
                  {t("Submit Sitemap")}
                </label>
                <div className="relative mt-2 rounded-md ">
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      className=" flex-auto rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-sm sm:leading-6"
                      placeholder="https://www.example.com/sitemap.xml"
                    />
                    <div className="flex justify-center">
                      <a
                        style={{ backgroundColor: "black" }}
                        className="rounded-md px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                        href="/create-new-chatbot"
                      >
                        {t("Load sitemap")}
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="mt-16">
                <label
                  htmlFor=""
                  className="block text-sm font-medium leading-6 text-gray-900 my-2"
                >
                  {t("Included Links")}
                </label>
                {showDeleteAllButton && (
                  <div className="flex justify-end">
                    <button
                      className="text-sm text-red-700"
                      onClick={handleDeleteAllLinks}
                    >
                      {t("Delete all")}
                    </button>
                  </div>
                )}
                {links.map((link, index) => (
                  <div
                    key={index}
                    className="relative mt-2 rounded-md shadow-sm"
                  >
                    <div className="flex items-center space-x-2">
                      {" "}
                      {/* space-x-2 is added to maintain some space between the elements */}
                      <input
                        type="text"
                        className="flex-grow rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-sm sm:leading-6"
                        placeholder="https://www.example.com/sitemap.xml"
                        value={link.link}
                        onChange={(e) =>
                          handleLinkChange(index, e.target.value)
                        }
                      />
                      <div className="flex-none text-center w-8">
                        {" "}
                        {/* Fix the width for consistent alignment */}
                        {link.length}
                      </div>
                      <button
                        type="button"
                        className="flex-none text-zinc-600 hover:text-zinc-900"
                        onClick={() => handleDeleteLink(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 text-red-600 ml-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}

                <div className="my-2 flex justify-end">
                  <button
                    type="button"
                    className="flex items-center rounded-md border border-transparent bg-zinc-200 py-1 px-2 text-sm font-medium text-black shadow-sm hover:bg-zinc-300"
                    onClick={handleAddLink}
                  >
                    {t("Add")}
                  </button>
                </div>
              </div>
            </div>
          )}
          {selectedType === "qna" && (
            <div className="max-w-3xl m-auto">
              {/* Q&A content */}

              <div className="mt-16">
                {showDeleteAllqna && (
                  <div className="flex justify-end">
                    <button
                      className="text-sm text-red-700"
                      onClick={handleDeleteAllqna}
                    >
                      {t("Delete all")}
                    </button>
                  </div>
                )}
                {qna.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="relative mt-2 rounded-md shadow-sm"
                    >
                      <div>
                        <label
                          htmlFor={`question-${index}`}
                          className="mt-8 text-sm text-zinc-700"
                        >
                          {t("Questions")} {index + 1}
                        </label>
                        <textarea
                          style={{ direction: isRTL ? "rtl" : "ltr" }}
                          id={`question-${index}`}
                          value={item.question}
                          onChange={(e) =>
                            handleQnaChange(index, "question", e.target.value)
                          }
                          className="min-w-0 p-1 w-full appearance-none rounded-md border border-zinc-900/10 bg-white px-3 shadow-md shadow-zinc-800/5 placeholder:text-zinc-400 focus:border-violet-500 focus:outline-none focus:ring-4 focus:ring-violet-500/10 sm:text-sm text-gray-900"
                          rows="3"
                        ></textarea>
                      </div>
                      <div>
                        <label
                          htmlFor={`answer-${index}`}
                          className="mt-8 text-sm text-zinc-700"
                        >
                          {t("Answer")} {index + 1}
                        </label>
                        <textarea
                          style={{ direction: isRTL ? "rtl" : "ltr" }}
                          id={`answer-${index}`}
                          value={item.answer}
                          onChange={(e) =>
                            handleQnaChange(index, "answer", e.target.value)
                          }
                          className="min-w-0 p-1 w-full appearance-none rounded-md border border-zinc-900/10 bg-white px-3 shadow-md shadow-zinc-800/5 placeholder:text-zinc-400 focus:border-violet-500 focus:outline-none focus:ring-4 focus:ring-violet-500/10 sm:text-sm text-gray-900"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="text-zinc-600 hover:text-zinc-900"
                        onClick={() => handleDeleteqna(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 text-red-600 ml-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </>
                ))}
                <div className="my-2 flex justify-end">
                  <button
                    type="button"
                    className="flex items-center rounded-md border border-transparent bg-zinc-200 py-1 px-2 text-sm font-medium text-black shadow-sm hover:bg-zinc-300"
                    onClick={handleAddqna}
                  >
                    {t("Add")}
                  </button>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
        <div>
          <div className=" my-8 max-w-2xl m-auto p-4 rounded border">
            <div>{t("Included sources")}</div>
            <div className="flex py-1 space-x-3"></div>
            <p
              className=" text-sm mt-4"
              style={{ direction: isRTL ? "rtl" : "ltr" }}
            >
              {t("Total detected characters:")}

              <span className="font-bold"> {characterCount}</span>

              <span className=" text-zinc-500">
                /{totalCharactersLimit} {t("limit")}
              </span>
            </p>
            <div className="flex justify-center py-4">
              <a
                style={{
                  backgroundColor: loadingStates["createnewchatbot"]
                    ? "#888888"
                    : "black",
                }}
                className="rounded-md w-full text-center py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                onClick={() => createNewChatbot("createnewchatbot")}
              >
                <div className="flex items-center justify-center">
                  <span style={{ marginRight: "0.5rem" }}>
                    {t("Create Chatbot")}
                  </span>
                  {loadingStates["createnewchatbot"] && (
                    <span className="flex space-x-1">
                      <span className="h-2 w-2 rounded-full animate-color"></span>
                      <span className="h-2 w-2 rounded-full animate-color200"></span>
                      <span className="h-2 w-2 rounded-full animate-color400"></span>
                    </span>
                  )}
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
