import { React, useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../supabase";
import "../App.css";
import { useTranslation } from "react-i18next";
export default function Login() {
  const { t } = useTranslation();
  useEffect(() => {
    // Initialize observer to monitor changes in the DOM
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const elementWithClassName = document.querySelector(
            ".supabase-auth-ui_ui-message.c-bRTXzr"
          );
          if (elementWithClassName) {
            const innerText = elementWithClassName.textContent;
            const translatedText = t(innerText); // Get the translation
            if (translatedText) {
              elementWithClassName.textContent = translatedText; // Replace with translated text
            }
          }
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });

    // Cleanup: disconnect the observer when component unmounts
    return () => observer.disconnect();
  }, [t]);
  return (
    <div className="flex justify-center items-center min-h-screen bg-white">
      <div className="bg-gray-70 p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="text-center mb-4 font-bold text-xl">{t("Login")}</div>
        <Auth
          supabaseClient={supabase}
          providers={[]}
          appearance={{
            theme: ThemeSupa,
            extend: true,
          }}
          showLinks={false}
          magicLink={true}
          view="magic_link"
          localization={{
            variables: {
              magic_link: {
                confirmation_text: t("Check your email for the magic link"),
                button_label: t("Send Magic Link"),
                email_input_label: t("Email Address"),
                email_input_placeholder: t("Your Email Address"),
                loading_button_label: t("Sending Magic Link..."),
              },
            },
          }}
        />
      </div>
    </div>
  );
}
