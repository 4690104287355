import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { findPackage } from "../../config";
import { useNavigate } from "react-router-dom";

export default function Chatbot() {
  // const [data, setData] = useState(null);
  // if (data === null) {
  //   getdata();
  //   // console.log("getting data");
  // }
  // async function getdata() {
  //   const response = await api.get("/bot");
  //   console.log("Total Length: " + response.data.length);
  //   setData(response.data);
  //   console.log("getting data");
  // }
  // useEffect(() => {

  // }, []);
  const [totalBotsLimit, settotalBotsLimit] = useState(0);
  const [totalBotsCreated, settotalBotsCreated] = useState(0);
  const navigate = useNavigate();

  const { data, status } = useQuery(["getAllBots"], async () => {
    const response = await api.get("/bot");
    //console.log(JSON.stringify(response.data));
    console.log("Total Length: " + response.data.length);
    settotalBotsCreated(response.data.length);

    const packageDetailsresponse = await api.get(`/user/packageDetails`);
    let temppackagename =
      packageDetailsresponse.data.data.packageName.toLowerCase();
    settotalBotsLimit(findPackage(temppackagename).total_bot_limit);
    console.log(response.status);
    return response.data;
  });
  const chatbots = [
    { name: "Chatbot 1" },

    // Add more chatbots here
  ];

  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <div>
      <main id="skip">
        <section className="bg-white">
          <div className=" max-w-7xl mx-auto py-10 sm:py-24 px-4 sm:px-6 lg:px-8">
            <main className="isolate">
              <div className="relative px-6 lg:px-8 py-16 sm:py-8">
                <div className="pb-72">
                  <div className="max-w-3xl w-full m-auto">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h1 className="text-2xl md:text-3xl font-extrabold text-black">
                          {t("My Chatbots")}
                        </h1>
                        {status === "success" && (
                          <p className="text-sm font-normal">
                            {totalBotsLimit} {t("chatbot limit")}
                          </p>
                        )}
                      </div>

                      {chatbots.length !== 0 && ( // Only show the button if chatbots array is not empty
                        <div className="flex justify-center">
                          <a
                            style={{
                              backgroundColor: "black",
                              direction: isRTL ? "rtl" : "ltr",
                            }}
                            className="rounded-md px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                            // href="/create-new-chatbot"
                            onClick={() => {
                              if (totalBotsCreated >= totalBotsLimit) {
                                alert(t("Total Bots Limit Reached."));
                              } else {
                                navigate("/create-new-chatbot");
                              }
                            }}
                          >
                            {t("New Chatbot")}
                          </a>
                        </div>
                      )}
                    </div>
                    {status === "loading" ? (
                      // Show loader while data is loading
                      <div className="flex justify-center py-8">
                        <div className="loader"></div>
                      </div>
                    ) : status === "success" && data?.length > 0 ? (
                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 py-4">
                        {data?.map((chatbot) => (
                          <div
                            key={chatbot.id}
                            className="flex items-center justify-center"
                          >
                            <a href={"/open-chatbot/" + chatbot.id}>
                              <div className="flex flex-col justify-between w-40 border rounded relative overflow-hidden">
                                <img
                                  srcSet="https://backend.chatbase.co/storage/v1/object/public/chatbase/chatbot-placeholder.png?width=256&quality=50 1x, https://backend.chatbase.co/storage/v1/object/public/chatbase/chatbot-placeholder.png?width=640&quality=50 2x"
                                  src="https://backend.chatbase.co/storage/v1/object/public/chatbase/chatbot-placeholder.png?width=640&quality=50"
                                  width="200"
                                  height="200"
                                  className="border-none h-40 w-40 object-cover"
                                  style={{ color: "transparent" }}
                                />
                                <div className="px-1 flex justify-center items-center h-14">
                                  <h3 className="text-xs md:text-sm font-semibold text-center overflow-hidden m-auto">
                                    {chatbot.name}
                                  </h3>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </section>
      </main>
    </div>
  );
}
